import About from "./screen/About";
import HideForm from "./screen/HideForm";
import { useRef } from "react";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import ChangeLang from "./components/ChangeLang/ChangeLang";


const App = () => {
  i18n.use(initReactI18next).init({
    resources: require('./lang.json'),
    lng: localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en',
    fallbackLng: localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en',
  });

  const formRef = useRef(null)

  const letsTry = () => {
    formRef.current.scrollIntoView({ behavior: "smooth", })
  }
  return (
    <>
      <ChangeLang />
      <About letsTry={letsTry} />
      <div ref={formRef}>
        <HideForm />
      </div>
    </>
  );
}

export default App;
