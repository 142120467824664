import { useTranslation } from "react-i18next";

import { useEffect, useState } from "react";
import { Select } from 'antd'
import logoRu from '../../assets/ru.png'
import logoUs from '../../assets/us.png'

const ChangeLang = () => {
    const [currentLang, setCurrentLang] = useState(localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en',)
    const { i18n } = useTranslation();

    useEffect(() => {
        localStorage.setItem("lang", currentLang);
        i18n.changeLanguage(currentLang);
    }, [currentLang])


    return <div style={{
        position: 'fixed',
        zIndex: '2',
        right: '32px',
        top: '32px'
    }}>
        <Select size="large" options={[
            {
                value: 'en',
                label: <img alt='en' src={logoUs} width={24} />
            },
            {
                value: 'ru',
                label: <img alt='ru' src={logoRu} width={24} />
            },
        ]}
            defaultValue={currentLang}
            onChange={setCurrentLang}
        />
    </div>

}

export default ChangeLang