import { Flex, Input, Button, Col, Row, Modal } from "antd"
import ScreenLayout from "../../components/ScreenLayout/ScreenLayout"
import { useState } from "react"
import { Trans } from "react-i18next";
import { useTranslation } from "react-i18next";
import qrcode from '../../assets/qr.png'



const HideForm = () => {
    const [sourceText, setSourceText] = useState('')
    const [obfuscateText, setObfuscateText] = useState('')
    const { t } = useTranslation();

    const terms = () => {
        Modal.info({
            title: t('terms.title'),
            content: (
                <>
                    <p>{t('terms.text')}</p>
                    <ul>
                        {t('terms.list', { returnObjects: true }).map(item => <li>{item}</li>)}
                    </ul>
                </>
            ),
            width: 600,
            onOk() { },
        });
    };


    const handleObfuscate = async () => {
        console.log('ss');
        const result = await fetch('https://api.hidegpt.app/obfuscate', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ text: sourceText })
        })
        const data = await result.json()
        if (data.text) {
            setObfuscateText(data.text)
        }
        else {
            console.error('Some error');
        }
    }



    return <ScreenLayout>
        <h2 style={{ marginBottom: '48px', textAlign: 'center' }}><Trans t={t} i18nKey='obfTitle' /></h2>
        <Row>
            <Col xs={24} xl={10}>
                <Input.TextArea
                    minLength={50}
                    placeholder={t('phSource')}
                    style={{ height: 600, fontSize: '1.3rem', resize: 'none', borderRadius: '32px', padding: '16px' }}
                    onChange={e => setSourceText(e.target.value)}
                />
            </Col>
            <Col xs={24} xl={4}>
                <Row justify='center'>
                    <Col xs={24} xl={24}>
                        <Flex vertical align="center" gap={16}>
                            <Button
                                danger
                                type="primary"
                                onClick={() => handleObfuscate()}
                                style={{ width: '172px', height: '48px', fontSize: '1rem', borderRadius: '32px', margin: '32px' }}>
                                {t('bObfuscate')}
                            </Button>
                        </Flex>
                    </Col>
                    <Col xs={0} xl={24}>
                        <Flex vertical align="center" gap={16}>
                            <Button
                                type="primary"
                                style={{ width: '172px', height: '48px', fontSize: '1rem', borderRadius: '32px', margin: '32px' }}
                                onClick={() => window.open('https://donate.stream/HideGPT', '_blank')}
                            >
                                {t('bDonate')}
                            </Button >
                            <img alt='donate' src={qrcode} width='128px' />
                        </Flex>
                    </Col>
                    <Col xs={24} xl={24}>
                        <Flex vertical align="center" gap={16}>
                            <Button
                                type="link"
                                onClick={terms}
                                style={{ width: '256px', height: '64px', fontSize: '0.7rem', borderRadius: '32px', margin: '32px' }}>
                                {t('bTerms')}
                            </Button>
                        </Flex>
                    </Col>
                </Row>
            </Col>
            <Col xs={24} xl={10}>
                <Input.TextArea
                    minLength={50}
                    placeholder={t('phObfuscare')}
                    value={obfuscateText}
                    style={{ height: 600, fontSize: '1.3rem', resize: 'none', borderRadius: '32px', padding: '16px' }} />
            </Col>
            <Col xs={24} xl={0}>
                <Flex vertical align="center">
                    <Button type="primary" style={{ width: '172px', height: '48px', fontSize: '1rem', borderRadius: '32px', margin: '32px' }} onClick={() => window.open('https://donate.stream/HideGPT', '_blank')}> {t('bDonate')}</Button >
                </Flex>
            </Col>
        </Row>
    </ScreenLayout >
}

export default HideForm