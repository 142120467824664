import ScreenLayout from "../../components/ScreenLayout/ScreenLayout"
import { Flex, Button, Row, Col, Typography } from "antd"
import Logo from './../../assets/logo.png'
import { useTranslation } from "react-i18next";
import { Trans } from "react-i18next";

const About = ({ letsTry }) => {
    const { t } = useTranslation();

    return <ScreenLayout>
        <Row >
            <Col xs={24} xl={12}>
                <Flex vertical gap={8} >
                    <h1><Trans i18nKey='landTitle' /></h1>
                    <div ><Trans t={t} i18nKey='landText.question' /></div>
                    <div><Trans t={t} i18nKey='landText.answer' /></div>
                    <div><Trans t={t} i18nKey='landText.about_1' /></div>
                    <div><Trans t={t} i18nKey='landText.about_2' /></div>
                    <div><Trans t={t} i18nKey='landText.youtube' components={{ a: <a /> }} /></div>
                    <div><Trans t={t} i18nKey='landText.languages' /></div>
                    <div><Trans t={t} i18nKey='landText.tg_bot' components={{ a: <a /> }} /></div>
                    <div><Trans t={t} i18nKey='landText.tg_group' components={{ a: <a /> }} /></div>
                    <div><Trans t={t} i18nKey='landText.contact' components={{ a: <a /> }} /></div>
                    <Button onClick={letsTry} size="large" type='primary' style={{ width: '256px', height: '64px', fontSize: '1.2rem', borderRadius: '32px', alignSelf: 'center', marginTop: '48px' }}>
                        <Trans t={t} i18nKey='bTryNow' />
                    </Button>
                </Flex>
            </Col>
            <Col xs={0} xl={12}>
                <Flex vertical gap={8} align="center">
                    <img src={Logo} alt='' style={{ borderRadius: '64px', alignSelf: 'center', justifySelf: 'center' }} />
                </Flex>
            </Col>
        </Row>
    </ScreenLayout>
}

export default About